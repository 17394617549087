<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="15"
    viewBox="0 0 9 15"
    fill="none"
    stroke="currentColor"
  >
    <g clip-path="url(#clip0_31306_211545)">
      <path
        d="M7.88625 13.7109L1.40625 7.18094L7.93625 0.710938"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_31306_211545">
        <rect width="8.65" height="14.41" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
